import { captureRemixErrorBoundaryError, withSentry } from "@sentry/remix";
import { useEffect } from "react";
import {
  json,
  type LinksFunction,
  type LoaderFunctionArgs,
} from "@remix-run/node";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import {
  ExternalScripts,
  type ExternalScriptsHandle,
} from "remix-utils/external-scripts";
// import {
//   createMongoAbility,
//   type SubjectRawRule,
//   type ExtractSubjectType,
//   type MongoQuery,
// } from "@casl/ability";
// import { packRules, unpackRules, type PackRule } from "@casl/ability/extra";

import { authenticator } from "#server/auth/auth.server.ts";
import { Toaster } from "react-hot-toast";
import { ClientToast } from "#client/common/toast.tsx";
// import { AbilityContext } from "#client/permission/context.ts";
// import { WebPermissionService } from "#server/permission/webPermissionService.server.ts";
// import type { AppAbility, ACTION, SUBJECT } from "#server/permission/types.ts";
import { env } from "#env.server.ts";
// import { superjson, useSuperLoaderData } from "#shared/superJson.ts";
import { getToast } from "remix-toast";
import { asyncSingleton } from "@es/core/utils.server.ts";
import {
  OneTimeTierProduct,
  type OneTimeTierProductData,
} from "@es/mongodb/models/OneTimeTierProduct.server.ts";
import {
  AddOnStorageProduct,
  type AddOnStorageProductData,
} from "@es/mongodb/models/AddOnStorageProduct.server.ts";
import { invariantResponse } from "#server/common/guards.ts";
import { MarketingNav } from "#client/marketing/MarketingNav.tsx";
import { LinkButton } from "#client/common/ui/LinkButton.tsx";
import { GlobalLoading } from "#client/common/ui/GlobalLoader.tsx";

import "@fontsource/dm-serif-text/400.css";
import tailwindStylesheetUrl from "#styles/tailwind.css?url";
import { Chatbot } from "#client/chatbot/helpscout";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: tailwindStylesheetUrl },
];

export let handle: ExternalScriptsHandle = {
  scripts: [
    {
      src: "https://lmsqueezy.com/affiliate.js",
      defer: true,
    },
    {
      src: "https://www.googletagmanager.com/gtag/js?id=AW-16489687744",
      defer: true,
    },
  ],
};

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await authenticator.isAuthenticated(request);

  // const currentUser = user ? await requireUserSession(request) : null;
  // const permissionService = new WebPermissionService();
  // const rules = currentUser
  //   ? packRules(permissionService.rules({ currentUser }))
  //   : null;

  const { toast, headers } = await getToast(request);
  const plusEventProduct = await asyncSingleton(
    "plusEventProduct",
    async () => {
      const p: OneTimeTierProductData | null = await OneTimeTierProduct.findOne(
        {
          id: env.LEMON_SQUEEZY_PLUS_PRODUCT_ID,
        }
      ).lean();

      return p
        ? {
            id: p.id,
            priceUSD: p.priceUSD,
            eventCap: p.eventCap,
            storageCap: p.storageCap,
            name: p.name,
            limit: p.limit,
          }
        : null;
    }
  );
  const premiumEventProduct = await asyncSingleton(
    "premiumEventProduct",
    async () => {
      const p: OneTimeTierProductData | null = await OneTimeTierProduct.findOne(
        {
          id: env.LEMON_SQUEEZY_PREMIUM_PRODUCT_ID,
        }
      ).lean();

      return p
        ? {
            id: p.id,
            priceUSD: p.priceUSD,
            eventCap: p.eventCap,
            storageCap: p.storageCap,
            name: p.name,
            limit: p.limit,
          }
        : null;
    }
  );
  const plusGiftProduct = await asyncSingleton("plusGiftProduct", async () => {
    const p: OneTimeTierProductData | null = await OneTimeTierProduct.findOne({
      id: env.LEMON_SQUEEZY_PLUS_GIFT_PRODUCT_ID,
    }).lean();

    return p
      ? {
          id: p.id,
          priceUSD: p.priceUSD,
          eventCap: p.eventCap,
          storageCap: p.storageCap,
          name: p.name,
          limit: p.limit,
        }
      : null;
  });
  const premiumGiftProduct = await asyncSingleton(
    "premiumGiftProduct",
    async () => {
      const p: OneTimeTierProductData | null = await OneTimeTierProduct.findOne(
        {
          id: env.LEMON_SQUEEZY_PREMIUM_GIFT_PRODUCT_ID,
        }
      ).lean();

      return p
        ? {
            id: p.id,
            priceUSD: p.priceUSD,
            eventCap: p.eventCap,
            storageCap: p.storageCap,
            name: p.name,
            limit: p.limit,
          }
        : null;
    }
  );
  const addOnStorageProduct = await asyncSingleton(
    "addOnStorageProduct",
    async () => {
      const p: AddOnStorageProductData | null =
        await AddOnStorageProduct.findOne({
          id: env.LEMON_SQUEEZY_ADD_STORAGE_PRODUCT_ID,
        }).lean();

      return p
        ? {
            id: p.id,
            priceUSD: p.priceUSD,
            storagePerQty: p.storagePerQty,
            limit: p.limit,
          }
        : null;
    }
  );

  invariantResponse(plusEventProduct, "Could not find plus event product");
  invariantResponse(
    premiumEventProduct,
    "Could not find premium event product"
  );
  invariantResponse(plusGiftProduct, "Could not find plus event gift product");
  invariantResponse(
    premiumGiftProduct,
    "Could not find premium event gift product"
  );
  invariantResponse(
    addOnStorageProduct,
    "Could not find addon storage product"
  );

  return json(
    {
      isAuthenticated: user !== null,
      toastMessage: toast,
      // rules,
      plusEventProduct,
      premiumEventProduct,
      plusGiftProduct,
      premiumGiftProduct,
      addOnStorageProduct,
      // Seo
      seoIndex: env.SEO_INDEX,
      projectEnv: env.PROJECT_ENV,
    },
    {
      headers,
    }
  );
}

function ErrorLayout(props: { children: React.ReactNode }) {
  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <MarketingNav />
        <div className="px-6 py-10 lg:px-8">{props.children}</div>
        <ScrollRestoration />
        <Toaster position="top-center" />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <ErrorLayout>
          <div className="flex flex-col items-center justify-center gap-2 py-10">
            <h1 className="text-2xl font-bold">Not Found</h1>
            <p>Sorry, but the page you are looking for does not exist</p>
            <div className="mt-4">
              <LinkButton to="/">Back to Home</LinkButton>
            </div>
          </div>
        </ErrorLayout>
      );
    }
    return (
      <ErrorLayout>
        <div className="flex flex-col items-center justify-center gap-2 py-10">
          <h1 className="text-2xl font-bold">Error</h1>
          <p>{error.statusText}</p>
          <div className="mt-4">
            <LinkButton to="/">Back to Home</LinkButton>
          </div>
        </div>
      </ErrorLayout>
    );
  }
  // @ts-ignore
  if (error && error?.message === "Too Many Requests") {
    return (
      <ErrorLayout>
        <div className="flex flex-col items-center justify-center gap-2 py-10">
          <h1 className="text-2xl font-bold">Error</h1>
          <p>
            Too many requests. Please slow down the number of requests you are
            making.
          </p>
          <div className="mt-4">
            <LinkButton to="/">Back to Home</LinkButton>
          </div>
        </div>
      </ErrorLayout>
    );
  }

  return (
    <ErrorLayout>
      <div className="flex flex-col items-center justify-center gap-2 py-10">
        <h1 className="text-2xl font-bold">Error</h1>
        <p>
          Sorry, but something went wrong. Please try again or reach out to us
          at support@eventshare.io
        </p>
        <div className="mt-4">
          <LinkButton to="/">Back to Home</LinkButton>
        </div>
      </div>
    </ErrorLayout>
  );
}

function App() {
  const { toastMessage, projectEnv } = useLoaderData<typeof loader>();

  useEffect(() => {
    if (toastMessage) {
      if (toastMessage.type === "error") {
        ClientToast.error(toastMessage.message);
      } else if (toastMessage.type === "info") {
        ClientToast.info(toastMessage.message);
      } else {
        ClientToast.success(toastMessage.message);
      }
    }
  }, [toastMessage]);

  // add gtag
  useEffect(() => {
    if (window && window.gtag) {
      return;
    }

    window.dataLayer = window.dataLayer || [];

    window.gtag = function () {
      window.dataLayer?.push(arguments);
    };

    window.gtag("js", new Date());
    window.gtag("config", "AW-16489687744");
  }, [projectEnv]);

  return (
    <html lang="en" className="h-full">
      <head>
        <script src="/affiliate.js"></script>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-MNNWFKJV');`,
          }}
        ></script> */}

        <Meta />
        <Links />
      </head>
      <body className="h-full">
        <GlobalLoading />
        <Outlet />
        <Chatbot />
        <ScrollRestoration />
        <ExternalScripts />
        <Scripts />
        <Toaster
          position="top-center"
          // we want the toast to appear on top of everything, even modals
          containerStyle={{ zIndex: 999999999999999 }}
        />
      </body>
    </html>
  );
}

export default withSentry(App);
